.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000075;
    width: 100vw;
    height: 100vh; /* Full viewport height */
  }
  
  .spinner {
    border: 8px solid rgba(0, 0, 0, 0.5); /* Light border */
    border-top: 8px solid white; /* Blue border */
    border-radius: 50%;
    
    width: 75px;
    height: 75px;
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  