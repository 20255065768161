.fade-in {
    opacity: 0;
    transform: translateY(10px); /* Start with a slight upward shift */
    transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Define transition effect */
  }
  
  .fade-in.visible {
    opacity: 1;
    transform: translateY(0); /* Return to normal position */
  }
  